import { useMemo } from 'react';
import makeSearchTextRegex from '../sci-ui-components/utils/makeSearchTextRegex';
import { StringFields } from '../utils/tsUtils';

export type Filter<TItem extends object> = (item: TItem) => boolean;

export default function useFilteredData<TItem extends object>(
  data: TItem[] | undefined,
  searchTextField: StringFields<TItem>,
  searchText: string | null | undefined,
  filters: ((item: TItem) => boolean)[] = []
) {
  return useMemo(() => {
    const searchRegex = searchText ? makeSearchTextRegex(searchText) : null;
    return data?.filter((item) => {
      if (searchRegex && !searchRegex.test(String(item[searchTextField]))) {
        return false;
      }
      return !filters.some((f) => !f(item));
    });
    /*eslint-disable react-hooks/exhaustive-deps*/
  }, [data, searchText, ...filters]);
}
