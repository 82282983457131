import { useCallback, useMemo } from 'react';
import { CollectiblesSetVariation } from '@sportscardinvestor/schemas';
import { useQueryClient } from 'react-query';
import uniqBy from 'lodash/uniqBy';
import {
  useAuthenticatedMMAPIInfiniteQuery,
  useAuthenticatedMMAPIQuery,
  useAuthenticatedMMAPIQueries,
  MmApiInfiniteQueryOptions,
  MmApiQueryOptions,
  MmApiInput,
  MmApiOutput,
  mmApiClient,
} from '../../services/mmApiX/index';
import useStableFunctionIdentity from '@/sci-ui-components/hooks/useStableFunctionIdentity';

export type { CollectiblesSetVariation } from '@sportscardinvestor/schemas';
export type UseSetVariationsListInput = MmApiInput['private']['setVariations']['list'] & { useAdminApi?: boolean };
export type UseSetVariationsListOutput = MmApiOutput['private']['setVariations']['list'];
export type UseSetVariationsListSortBy = Exclude<UseSetVariationsListInput['sort'], void>[number]['sortBy'];

export const useSetVariationsListKeyPrefix = 'private.setVariations.list';
export type UseSetVariationsListQueryKey = [typeof useSetVariationsListKeyPrefix, UseSetVariationsListInput];
function makeUseSetVariationsListQueryKey(input: UseSetVariationsListInput): UseSetVariationsListQueryKey {
  return [useSetVariationsListKeyPrefix, input];
}

export function useSetVariationsListPaginated(
  baseInput: UseSetVariationsListInput,
  options?: MmApiQueryOptions<UseSetVariationsListOutput, UseSetVariationsListQueryKey>
) {
  const input = {
    ...baseInput,
    offset: baseInput.offset ?? 0,
  };
  const queryKey = makeUseSetVariationsListQueryKey(input);
  const result = useAuthenticatedMMAPIQuery(
    queryKey,
    async () => mmApiClient[input.useAdminApi ? 'admin' : 'private'].setVariations.list.query(input),
    options
  );
  const totalCountInput = {
    ...input,
    offset: 0,
  };
  const totalCountQueryKey = makeUseSetVariationsListQueryKey(totalCountInput);
  const totalCountResult = useAuthenticatedMMAPIQuery(
    totalCountQueryKey,
    async () => mmApiClient[input.useAdminApi ? 'admin' : 'private'].setVariations.list.query(input),
    options
  );

  const items: CollectiblesSetVariation[] = result.data?.items ?? [];

  return {
    ...result,
    items,
    totalCount: totalCountResult.data?.totalCount ?? null,
  };
}

export function useSetVariationsListInfinite(
  input: Omit<UseSetVariationsListInput, 'offset'>,
  options?: MmApiInfiniteQueryOptions<UseSetVariationsListOutput, UseSetVariationsListQueryKey>
) {
  const queryKey: UseSetVariationsListQueryKey = [useSetVariationsListKeyPrefix, input];
  const { useAdminApi, ...commonInput } = input;
  const result = useAuthenticatedMMAPIInfiniteQuery(
    queryKey,
    async ({ pageParam }: { pageParam?: number }) =>
      mmApiClient[useAdminApi ? 'admin' : 'private'].setVariations.list.query({
        ...commonInput,
        offset: pageParam ?? 0,
      }),
    {
      ...(options ?? {}),
      getNextPageParam: (_lastPage, pages): number | null => {
        const firstPage = pages[0];
        if (!firstPage || !firstPage.totalCount) {
          return null;
        }
        const totalFetched = pages.reduce((acc, page) => acc + page.items.length, 0);
        if (firstPage.totalCount <= totalFetched) {
          return null;
        }
        return pages.length * (input.limit ?? firstPage.items.length);
      },
    }
  );
  const pages = result.data?.pages;
  const items = useMemo<CollectiblesSetVariation[]>(
    () => uniqBy(pages?.map(({ items }) => items).flat() ?? [], 'id'),
    [pages]
  );
  const totalCount = result.data?.pages?.[0]?.totalCount ?? null;

  const { hasNextPage, fetchNextPage } = result;
  const fetchNextPageIfAvailable = useCallback(() => {
    if (hasNextPage) {
      fetchNextPage();
    }
  }, [hasNextPage, fetchNextPage]);

  return {
    ...result,
    items,
    totalCount,
    fetchNextPageIfAvailable,
    fetchNextPage,
    hasNextPage,
  };
}

interface UseSetVariationsByIdsInput {
  setVariationIds: string[];
  useAdminApi: boolean;
}

export function useSetVariationsByIds(
  { setVariationIds, useAdminApi }: UseSetVariationsByIdsInput,
  options?: MmApiQueryOptions<UseSetVariationsListOutput, UseSetVariationsListQueryKey>
) {
  const queryResults = useAuthenticatedMMAPIQueries(
    setVariationIds.map((setVariationId) => {
      const input = makeSetVariationsListInputById({
        setVariationId,
        useAdminApi,
      });
      return {
        queryFn: () => mmApiClient[useAdminApi ? 'admin' : 'private'].setVariations.list.query(input),
        queryKey: makeUseSetVariationsListQueryKey(input),
        options,
      };
    })
  );
  const result = queryResults.reduce<{ items: CollectiblesSetVariation[]; isLoading: boolean }>(
    (acc, { data, isLoading }) => {
      const option = data?.items?.[0];
      if (option) {
        acc.items.push(option);
      }
      acc.isLoading = acc.isLoading || isLoading;
      return acc;
    },
    { items: [], isLoading: false }
  );

  const queryClient = useQueryClient();

  const setSetVariationItem = useStableFunctionIdentity((item: CollectiblesSetVariation): void => {
    const queryKey = makeUseSetVariationsListQueryKey(
      makeSetVariationsListInputById({
        setVariationId: String(item.id),
        useAdminApi,
      })
    );
    queryClient.setQueryData<UseSetVariationsListOutput>(queryKey, {
      items: [item],
      totalCount: 1,
    });
  });

  return {
    ...result,
    setSetVariationItem,
  };
}

interface UseSetVariationByIdInput {
  setVariationId: string;
  useAdminApi: boolean;
}

function makeSetVariationsListInputById({
  setVariationId,
  useAdminApi,
}: UseSetVariationByIdInput): UseSetVariationsListInput {
  return {
    filters: {
      setVariationIds: [setVariationId],
    },
    useAdminApi,
    limit: 1,
  };
}
export function useSetVariationById(
  { useAdminApi, setVariationId }: UseSetVariationByIdInput,
  options?: MmApiQueryOptions<UseSetVariationsListOutput, UseSetVariationsListQueryKey>
) {
  const {
    items: [item],
    setSetVariationItem,
    ...rest
  } = useSetVariationsByIds(
    {
      setVariationIds: [setVariationId],
      useAdminApi,
    },
    options
  );

  return {
    ...rest,
    item: item ?? null,
    setSetVariationItem,
  };
}
