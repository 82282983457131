import { create } from 'zustand';
import { useShallow } from 'zustand/react/shallow';
import QueryString from 'qs';
import { NumericDateRange } from '@/sci-ui-components/utils/date';
import { createQueryStringStoreSync } from '@/utils/zutandHelpers/queryString';
import { getBooleanParam, getNumericParam, getStringParam, makeBooleanParam } from '@/hooks/useQueryString';
import { CollectibleType, collectibleTypes } from '@sportscardinvestor/schemas';

interface CollectibleFlyoutState {
  collectibleId: number | null;
  collectibleType: CollectibleType;
  isCustom: boolean;
  dateRangeInDays: NumericDateRange;
}

const queryKeys: Record<keyof Omit<CollectibleFlyoutState, 'dateRangeInDays'>, string> = {
  collectibleId: 'flyout',
  collectibleType: 'flct',
  isCustom: 'flm',
} as const;

const defaultState: CollectibleFlyoutState = {
  collectibleId: null,
  collectibleType: 'sports-card',
  isCustom: false,
  dateRangeInDays: 30,
};

export const useCollectibleFlyoutStore = create<CollectibleFlyoutState>()(() => defaultState);

export function useCollectibleFlyoutStoreSelector<K extends keyof CollectibleFlyoutState>(
  ...items: K[]
): Pick<CollectibleFlyoutState, K> {
  return useCollectibleFlyoutStore(
    useShallow((state) => Object.fromEntries(items.map((key) => [key, state[key]])) as Pick<CollectibleFlyoutState, K>)
  );
}

export function closeCollectibleFlyout() {
  useCollectibleFlyoutStore.setState({ collectibleId: null, isCustom: false });
}
export function setCollectibleId({ collectibleId, isCustom }: { collectibleId: number; isCustom?: boolean }) {
  useCollectibleFlyoutStore.setState({ collectibleId, isCustom });
}
export function setDateRangeInDays(dateRangeInDays: NumericDateRange) {
  useCollectibleFlyoutStore.setState({ dateRangeInDays });
}

export const { QueryStringStoreSync: CollectibleFlyoutQueryStringSync } = createQueryStringStoreSync(
  useCollectibleFlyoutStore,
  {
    getQsFromState,
    getStateFromQs,
    defaultState,
  }
);

function getQsFromState({
  collectibleId,
  collectibleType,
  isCustom,
}: Partial<CollectibleFlyoutState>): QueryString.ParsedQs {
  if (!collectibleId) {
    return {
      [queryKeys.collectibleId]: undefined,
      [queryKeys.collectibleType]: undefined,
      [queryKeys.isCustom]: undefined,
    };
  }
  return {
    [queryKeys.collectibleId]: String(collectibleId),
    [queryKeys.collectibleType]: collectibleType ?? undefined,
    [queryKeys.isCustom]: makeBooleanParam(isCustom),
  };
}

function getStateFromQs(query: QueryString.ParsedQs): CollectibleFlyoutState {
  return {
    ...defaultState,
    collectibleId: getNumericParam(query, queryKeys.collectibleId, defaultState.collectibleId),
    collectibleType: getStringParam(query, queryKeys.collectibleType, defaultState.collectibleType, (v) =>
      collectibleTypes.includes(v as CollectibleType)
    ),
    isCustom: getBooleanParam(query, queryKeys.isCustom, defaultState.isCustom),
  };
}

export interface ShowCollectibleFlyoutParams {
  collectibleId: number;
  collectibleType: CollectibleType;
  isCustom: boolean;
}
export function showCollectibleFlyout({ collectibleId, collectibleType, isCustom }: ShowCollectibleFlyoutParams) {
  useCollectibleFlyoutStore.setState({
    collectibleId,
    collectibleType,
    isCustom,
  });
}
