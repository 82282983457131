import { useCallback, useState } from 'react';
import { useQueryClient } from 'react-query';
import { AggregatedCollectionItem } from '@sportscardinvestor/schemas';
import { CollectibleType } from '../../sci-ui-components/types/collectibleType';
import { mmApiClient, MmApiInput, MmApiOutput, MmApiQueryOptions, useAuthenticatedMMAPIQuery } from '@/services/mmApiX';
import { NoCategory } from './constants';

export type { AggregatedCollectionItem } from '@sportscardinvestor/schemas';
export type UseAggregatedCollectionItemsListInput = Exclude<
  MmApiInput['private']['collection']['aggregatedItems']['list'],
  void
>;
export type AggregatedCollectionItemsSortBy = Exclude<
  UseAggregatedCollectionItemsListInput['sort'],
  void
>[number]['sortBy'];
export type UseAggregatedCollectionItemsListOutput = MmApiOutput['private']['collection']['aggregatedItems']['list'];

export const useAggregatedCollectionItemsListKeyPrefix = 'private.collection.aggregatedItems.list' as const;
export type UseAggregatedCollectionItemsListKey = [
  typeof useAggregatedCollectionItemsListKeyPrefix,
  UseAggregatedCollectionItemsListInput
];
export const makeAggregatedCollectionItemsListQueryKey = (
  input: UseAggregatedCollectionItemsListInput
): UseAggregatedCollectionItemsListKey => [useAggregatedCollectionItemsListKeyPrefix, input];

export function useAggregatedCollectionItemsList(
  inputProp: UseAggregatedCollectionItemsListInput,
  options?: MmApiQueryOptions<UseAggregatedCollectionItemsListOutput, UseAggregatedCollectionItemsListKey>
) {
  const itemsInput: UseAggregatedCollectionItemsListInput = {
    ...inputProp,
    filters: {
      ...inputProp.filters,
      categoryId: inputProp.filters?.categoryId === NoCategory ? 'noCategory' : inputProp.filters?.categoryId,
    },
    offset: inputProp.offset ?? 0,
  };
  const { data: { items } = {}, ...otherItemsResult } = useAuthenticatedMMAPIQuery(
    makeAggregatedCollectionItemsListQueryKey(itemsInput),
    async () => mmApiClient.private.collection.aggregatedItems.list.query(itemsInput),
    options
  );
  const totalCountInput: UseAggregatedCollectionItemsListInput = {
    ...itemsInput,
    offset: 0,
  };
  const { data: { totalCount = 0 } = {} } = useAuthenticatedMMAPIQuery(
    makeAggregatedCollectionItemsListQueryKey(totalCountInput),
    async () => mmApiClient.private.collection.aggregatedItems.list.query(totalCountInput),
    options
  );

  return {
    ...otherItemsResult,
    items,
    totalCount,
  };
}

export function useAggregatedCollectionItemsListHelpers() {
  const queryClient = useQueryClient();
  const [isLoading, setIsLoading] = useState(false);

  /**
   * Fetch aggregated collection items list and save in query cache
   */
  const fetchAggregatedCollectionItemsList = useCallback(
    async (input: UseAggregatedCollectionItemsListInput) => {
      const key = makeAggregatedCollectionItemsListQueryKey(input);
      const cached = queryClient.getQueryData<UseAggregatedCollectionItemsListOutput>(key, {
        stale: false,
        exact: true,
      });
      if (cached) {
        return cached;
      }
      try {
        setIsLoading(true);
        const fetched = await mmApiClient.private.collection.aggregatedItems.list.query(input);
        queryClient.setQueryDefaults(key, {
          queryFn: () => mmApiClient.private.collection.aggregatedItems.list.query(input),
        });
        queryClient.setQueryData(key, fetched, {});
        return fetched;
      } finally {
        setIsLoading(false);
      }
    },
    [queryClient]
  );

  /**
   * Set in-memory value for useAggregatedCollectionItemByCollectibleId to avoid unnecessary api call
   */
  const setAggregatedCollectionItem = useCallback(
    async (item: AggregatedCollectionItem) => {
      const isCustom = !!item.customCollectible;
      const key = makeAggregatedCollectionItemsListQueryKey(
        makeInputFromAggregatedCollectionItemByCollectibleIdInput({
          collectibleId: isCustom ? item.customCollectible!.id : item.collectible!.id,
          collectibleType: isCustom ? item.customCollectible!.collectibleType : item.collectible!.collectibleType,
          isCustom,
        })
      );
      queryClient.setQueryData<UseAggregatedCollectionItemsListOutput>(key, {
        items: [item],
        totalCount: 1,
      });
    },
    [queryClient]
  );

  return {
    fetchAggregatedCollectionItemsList,
    setAggregatedCollectionItem,
    isLoading,
  };
}

interface AggregatedCollectionItemByCollectibleIdInput {
  collectibleType: CollectibleType;
  collectibleId: number | null;
  isCustom: boolean;
}

function makeInputFromAggregatedCollectionItemByCollectibleIdInput({
  collectibleId,
  collectibleType,
  isCustom,
}: AggregatedCollectionItemByCollectibleIdInput): UseAggregatedCollectionItemsListInput {
  return {
    filters: {
      collectibleType,
      [isCustom ? 'customCollectibleIds' : 'collectibleIds']: collectibleId ? [collectibleId] : [],
      customCollectible: isCustom ? 'customOnly' : 'nonCustomOnly',
    },
    limit: 1,
    offset: 0,
    viewBy: null, // NOTE: to be able to get collection item by collectible id regradless if it was sold or nor
  };
}

/**
 * Get aggregated collection item by collectible id
 */
export function useAggregatedCollectionItemByCollectibleId(
  input: AggregatedCollectionItemByCollectibleIdInput,
  options?: MmApiQueryOptions<UseAggregatedCollectionItemsListOutput, UseAggregatedCollectionItemsListKey>
): {
  item: AggregatedCollectionItem | null;
  isLoading: boolean;
} {
  const {
    items: [item = null] = [],
    totalCount: _totalCount,
    ...rest
  } = useAggregatedCollectionItemsList(makeInputFromAggregatedCollectionItemByCollectibleIdInput(input), {
    ...(options ?? {}),
    enabled: !!input.collectibleId && options?.enabled !== false,
  });

  return {
    ...rest,
    item,
  };
}
