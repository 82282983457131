import { useMemo } from 'react';
import { CollectibleGrade } from '@sportscardinvestor/schemas';
import { getIsRawGrade } from '@sportscardinvestor/collectible-helpers';
import {
  useAuthenticatedMMAPIQuery,
  MmApiQueryOptions,
  MmApiInput,
  MmApiOutput,
  mmApiClient,
} from '../../services/mmApiX/index';
import useFilteredData from '@/hooks/useFilteredData';

export type { CollectibleGrade } from '@sportscardinvestor/schemas';
export type UseGradesListInput = Omit<MmApiInput['private']['grades']['list'], 'limit'> & { useAdminApi?: boolean };
export type UseGradesListOutput = MmApiOutput['private']['grades']['list'];

export const useGradesListKeyPrefix = 'private.grade.list';
export type UseGradesListQueryKey = [typeof useGradesListKeyPrefix, UseGradesListInput];
function makeUseGradesListQueryKey(input: UseGradesListInput): UseGradesListQueryKey {
  return [useGradesListKeyPrefix, input];
}

export function useGradesListPaginated(
  input: Omit<UseGradesListInput, 'filters' | 'offset'>,
  options?: MmApiQueryOptions<UseGradesListOutput, UseGradesListQueryKey>
) {
  const queryKey = makeUseGradesListQueryKey(input);
  const { useAdminApi, ...commonInput } = input;
  const result = useAuthenticatedMMAPIQuery(
    queryKey,
    async () =>
      mmApiClient[useAdminApi ? 'admin' : 'private'].grades.list.query({
        ...commonInput,
        limit: 10000, // NOTE: get all
        sort: commonInput.sort ?? [
          {
            sortBy: 'gradeOrder',
            sortDirection: 'asc',
          },
          {
            sortBy: 'gradeName',
            sortDirection: 'asc',
          },
        ],
      }),
    options
  );

  return result;
}

export type GradesListFilters = Exclude<UseGradesListInput['filters'], void> & {
  excludeGradeIds?: number[];
};

/**
 * NOTE: There are not many grades to so it is better to always fetch all of them and apply filters on client side
 */
export function useFilterGrades({
  allGradeItems,
  filters,
}: {
  allGradeItems: CollectibleGrade[];
  filters: GradesListFilters;
}) {
  const { isGradedOnly, gradeIds, searchTitle, excludeGradeIds } = filters;
  const filterByGradedOnly = useMemo<(grade: CollectibleGrade) => boolean>(() => {
    if (!isGradedOnly) {
      return () => true;
    }
    return ({ name }) => !getIsRawGrade(name);
  }, [isGradedOnly]);
  const filterByIds = useMemo<(grade: CollectibleGrade) => boolean>(() => {
    if (!gradeIds?.length && !excludeGradeIds?.length) {
      return () => true;
    }
    const idsSet = new Set(gradeIds);
    const excludeIdsSet = new Set(excludeGradeIds);
    return ({ id }) => (!idsSet.size || idsSet.has(String(id))) && !excludeIdsSet.has(id);
  }, [gradeIds, excludeGradeIds]);
  const filteredItems = useFilteredData(allGradeItems, 'name', searchTitle, [filterByIds, filterByGradedOnly]);
  return filteredItems ?? [];
}

export function useGradesByIds({ gradeIds, useAdminApi }: { gradeIds: (number | string)[]; useAdminApi?: boolean }) {
  const { data, isLoading } = useGradesListPaginated(
    { useAdminApi },
    {
      enabled: !!gradeIds?.length,
    }
  );
  const filtered = useFilterGrades({
    allGradeItems: data?.items ?? [],
    filters: {
      gradeIds: gradeIds?.map(String),
    },
  });
  return {
    grades: filtered,
    isLoading,
  };
}

/**
 * Convenience hook to deal with deprecated APIs that accept grade names instead of IDs as filter param
 */
export function useGradeNamesByIds({ gradeIds }: { gradeIds: (number | string)[] }) {
  const { grades, isLoading } = useGradesByIds({ gradeIds });
  const gradeNames = useMemo(() => grades.map((v) => v.abbreviatedName ?? v.name), [grades]);
  return {
    gradeNames,
    isLoading,
  };
}

interface UseGradeByIdInput {
  gradeId: number;
  useAdminApi?: boolean;
}

export function useGradeById(
  { useAdminApi, gradeId }: UseGradeByIdInput,
  options?: MmApiQueryOptions<UseGradesListOutput, UseGradesListQueryKey>
) {
  const { data, ...rest } = useGradesListPaginated(
    {
      useAdminApi: useAdminApi ?? false,
    },
    options
  );
  const item = useMemo(() => data?.items.find((v) => v.id === gradeId) ?? null, [data, gradeId]);

  return {
    ...rest,
    item,
  };
}
