import { useMemo, useState } from 'react';
import { Collectible, CollectibleType } from '@sportscardinvestor/schemas';
import { useQueryClient } from 'react-query';
import { getCollectibleId, getCollectibleType } from '@sportscardinvestor/collectible-helpers';
import {
  useAuthenticatedMMAPIQuery,
  MmApiQueryOptions,
  mmApiClient,
  useAuthenticatedMMAPIQueries,
} from '../../services/mmApiX/index';
import { UseCollectiblesSearchOutput, UseCollectiblesSearchInput } from './useCollectiblesSearch';
import useStableFunctionIdentity from '@/sci-ui-components/hooks/useStableFunctionIdentity';

export interface UseCollectibleInput {
  collectibleType: CollectibleType;
  collectibleId: number;
}
export type UseCollectibleOutput = Collectible | null;
export const useCollectibleKeyPrefix = 'private.collectibles.search';
export type UseCollectibleQueryKey = [typeof useCollectibleKeyPrefix, UseCollectiblesSearchInput];

const makeInput = ({ collectibleId, collectibleType }: UseCollectibleInput): UseCollectiblesSearchInput => ({
  disableSpellCheck: true,
  limit: 1,
  filters: {
    collectibleTypes: [collectibleType],
    collectibleIds: [String(collectibleId)],
  },
  sort: [
    {
      sortBy: 'searchTitle.keyword',
      sortDirection: 'asc',
    },
    {
      sortBy: 'set.name.keyword',
      sortDirection: 'asc',
    },
    ...(collectibleType === 'sports-card'
      ? ([
          {
            sortBy: 'grade.order',
            sortDirection: 'asc',
          },
        ] as const)
      : []),
    {
      sortBy: 'stats.currentPopulationCount',
      sortDirection: 'asc',
    },
    {
      sortBy: 'id.keyword',
      sortDirection: 'asc',
    },
  ],
});
const makeUseCollectibleQueryKey = (input: UseCollectibleInput): UseCollectibleQueryKey => [
  useCollectibleKeyPrefix,
  makeInput(input),
];
const getFirstItem = (result: UseCollectiblesSearchOutput | null | undefined): Collectible | null => {
  return result?.items?.[0]?.item ?? null;
};
const fetchCollectible = async (input: UseCollectibleInput) => {
  const result = await mmApiClient.public.collectibles.search.query(makeInput(input));
  return getFirstItem(result);
};
const staleTime = 1000 * 60 * 60 * 6; // 6 hours

export function useCollectible(
  input: UseCollectibleInput,
  options?: MmApiQueryOptions<UseCollectibleOutput, UseCollectibleQueryKey>
) {
  const result = useAuthenticatedMMAPIQuery(makeUseCollectibleQueryKey(input), async () => fetchCollectible(input), {
    staleTime,
    ...options,
    enabled: !!input.collectibleId && options?.enabled !== false,
  });

  return {
    ...result,
    collectible: result.data,
  };
}

export interface UseCollectiblesInput {
  collectibleType: CollectibleType;
  collectibleIds: number[];
}

export function useCollectibles(
  { collectibleType, collectibleIds }: UseCollectiblesInput,
  options?: MmApiQueryOptions<UseCollectibleOutput, UseCollectibleQueryKey>
) {
  const results = useAuthenticatedMMAPIQueries(
    collectibleIds.map((collectibleId) => {
      const input: UseCollectibleInput = {
        collectibleId,
        collectibleType,
      };
      return {
        queryKey: makeUseCollectibleQueryKey(input),
        queryFn: async () => fetchCollectible(input),
        options: {
          ...options,
          staleTime,
        },
      };
    })
  );
  const isLoading = results.some((qr) => qr.isLoading);
  const isError = results.some((qr) => qr.isError);
  const error = results.find((qr) => qr.error);
  const refetch = useStableFunctionIdentity(() => {
    results.forEach(({ refetch }) => refetch());
  });

  const collectiblesById = useMemo(
    () =>
      results.reduce<Record<number, Collectible>>((acc, { data: collectible }) => {
        if (collectible) {
          acc[Number(collectible.id)] = collectible;
        }
        return acc;
      }, {}),
    [results]
  );

  return {
    collectiblesById,
    isLoading,
    isError,
    error,
    refetch,
  };
}

/**
 * Exposes a function to fetch a collectible while still using react-query cache
 */
export function useFetchCollectible() {
  const queryClient = useQueryClient();
  const [isLoading, setIsLoading] = useState(false);

  async function fetchCollectibleById(input: UseCollectibleInput): Promise<Collectible | null> {
    const queryKey = makeUseCollectibleQueryKey(input);
    const cached = queryClient.getQueryData<UseCollectibleOutput>(queryKey);
    if (cached) {
      return cached;
    }
    setIsLoading(true);
    try {
      const fetched = await fetchCollectible(input);
      queryClient.setQueryData<UseCollectibleOutput>(queryKey, fetched);
      return fetched;
    } finally {
      setIsLoading(false);
    }
  }

  return {
    isLoading,
    fetchCollectibleById,
  };
}

/**
 * returns helper function to set collectible fetched in another hook in react-query cache for useCollectible
 * This allows skipping fetching collectible
 */
export function useCollectibleCacheHelper() {
  const queryClient = useQueryClient();

  const setCollectible = useStableFunctionIdentity((collectible: Collectible | null | undefined): void => {
    if (!collectible) {
      return;
    }
    const collectibleId = getCollectibleId(collectible);
    const collectibleType = getCollectibleType(collectible);
    const queryKey = makeUseCollectibleQueryKey({
      collectibleId,
      collectibleType,
    });
    queryClient.setQueryData<UseCollectibleOutput>(queryKey, collectible);
  });

  const setCollectibles = useStableFunctionIdentity(
    <TItem>(items: TItem[], getCollectibleFromItem: (item: TItem) => Collectible | null): void => {
      items.forEach((item) => {
        const collectible = getCollectibleFromItem(item);
        if (collectible) {
          setCollectible(collectible);
        }
      });
    }
  );

  return {
    setCollectible,
    setCollectibles,
  };
}
