import { useMemo } from 'react';
import { BoxType } from '@sportscardinvestor/schemas';
import {
  useAuthenticatedMMAPIQuery,
  MmApiQueryOptions,
  MmApiInput,
  MmApiOutput,
  mmApiClient,
} from '../../services/mmApiX/index';
import { anySealedWaxBoxTypeOptions } from './constants';
import useFilteredData from '@/hooks/useFilteredData';

export type { BoxType } from '@sportscardinvestor/schemas';
export type UseBoxTypesListInput = Omit<MmApiInput['private']['boxTypes']['list'], 'limit'> & {
  useAdminApi?: boolean;
  allowAny?: boolean;
};
export type UseBoxTypesListOutput = MmApiOutput['private']['boxTypes']['list'];

export const useBoxTypesListKeyPrefix = 'private.boxTypes.list';
export type UseBoxTypesListQueryKey = [typeof useBoxTypesListKeyPrefix, UseBoxTypesListInput];
function makeUseBoxTypesListQueryKey(input: UseBoxTypesListInput): UseBoxTypesListQueryKey {
  return [useBoxTypesListKeyPrefix, input];
}

export function useBoxTypesListPaginated(
  input: UseBoxTypesListInput,
  options?: MmApiQueryOptions<UseBoxTypesListOutput, UseBoxTypesListQueryKey>
) {
  const queryKey = makeUseBoxTypesListQueryKey(input);
  const { useAdminApi, allowAny, ...commonInput } = input;
  const { data, ...rest } = useAuthenticatedMMAPIQuery(
    queryKey,
    async () =>
      mmApiClient[useAdminApi ? 'admin' : 'private'].boxTypes.list.query({
        ...commonInput,
        limit: 10000, // NOTE: get all
        sort: commonInput.sort ?? [
          {
            sortBy: 'boxTypeName',
            sortDirection: 'asc',
          },
        ],
      }),
    options
  );

  const items = useMemo(
    () => (allowAny && !!data?.items ? [...anySealedWaxBoxTypeOptions, ...data.items] : data?.items),
    [data?.items, allowAny]
  );

  return {
    ...rest,
    items,
    totalCount: data?.totalCount,
  };
}

export type BoxTypesListFilters = Exclude<UseBoxTypesListInput['filters'], void>;

/**
 * NOTE: There are not many boxTypes to so it is better to always fetch all of them and apply filters on client side
 */
export function useFilterBoxTypes({
  allBoxTypeItems,
  filters,
}: {
  allBoxTypeItems: BoxType[];
  filters: BoxTypesListFilters;
}) {
  const { boxTypeIds, searchTitle } = filters;
  const filterByIds = useMemo<(boxType: BoxType) => boolean>(() => {
    if (!boxTypeIds?.length) {
      return () => true;
    }
    const idsSet = new Set(boxTypeIds);
    return ({ id }) => idsSet.has(String(id));
  }, [boxTypeIds]);
  const filteredItems = useFilteredData(allBoxTypeItems, 'name', searchTitle, [filterByIds]);

  return filteredItems ?? [];
}

interface UseBoxTypeByIdInput {
  boxTypeId: number;
  useAdminApi?: boolean;
}

export function useBoxTypeById(
  { useAdminApi, boxTypeId }: UseBoxTypeByIdInput,
  options?: MmApiQueryOptions<UseBoxTypesListOutput, UseBoxTypesListQueryKey>
) {
  const { items, ...rest } = useBoxTypesListPaginated(
    {
      useAdminApi: useAdminApi ?? false,
    },
    options
  );
  const item = useMemo(() => items?.find((v) => v.id === boxTypeId) ?? null, [items, boxTypeId]);

  return {
    ...rest,
    item,
  };
}
