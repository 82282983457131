import { useCallback, useMemo } from 'react';
import { CollectiblesSet, CollectibleType } from '@sportscardinvestor/schemas';
import { makeSetDisplayName } from '@sportscardinvestor/collectible-helpers';
import { useQueryClient } from 'react-query';
import uniqBy from 'lodash/uniqBy';
import {
  useAuthenticatedMMAPIInfiniteQuery,
  useAuthenticatedMMAPIQuery,
  useAuthenticatedMMAPIQueries,
  MmApiInfiniteQueryOptions,
  MmApiQueryOptions,
  MmApiInput,
  MmApiOutput,
  mmApiClient,
} from '../../services/mmApiX/index';
import useStableFunctionIdentity from '@/sci-ui-components/hooks/useStableFunctionIdentity';

export type { CollectiblesSet } from '@sportscardinvestor/schemas';
export type UseSetsListInput = MmApiInput['private']['sets']['list'];
export type UseSetsListOutput = MmApiOutput['private']['sets']['list'];

export const useSetsListKeyPrefix = 'private.sets.list';
export type UseSetsListQueryKey = [typeof useSetsListKeyPrefix, UseSetsListInput];
function makeUseSetsListQueryKey(input: UseSetsListInput): UseSetsListQueryKey {
  return [useSetsListKeyPrefix, input];
}

export function useSetsListPaginated(
  baseInput: UseSetsListInput,
  options?: MmApiQueryOptions<UseSetsListOutput, UseSetsListQueryKey>
) {
  const input = {
    ...baseInput,
    offset: baseInput.offset ?? 0,
  };
  const queryKey = makeUseSetsListQueryKey(input);
  const result = useAuthenticatedMMAPIQuery(queryKey, async () => mmApiClient.private.sets.list.query(input), options);
  const totalCountInput = {
    ...input,
    offset: 0,
  };
  const totalCountQueryKey = makeUseSetsListQueryKey(totalCountInput);
  const totalCountResult = useAuthenticatedMMAPIQuery(
    totalCountQueryKey,
    async () => mmApiClient.private.sets.list.query(input),
    options
  );

  const items: CollectiblesSet[] = result.data?.items ?? [];

  return {
    ...result,
    items,
    totalCount: totalCountResult.data?.totalCount ?? null,
  };
}

export function useSetsListInfinite(
  input: Omit<UseSetsListInput, 'offset'>,
  options?: MmApiInfiniteQueryOptions<UseSetsListOutput, UseSetsListQueryKey>
) {
  const queryKey: UseSetsListQueryKey = [useSetsListKeyPrefix, input];
  const result = useAuthenticatedMMAPIInfiniteQuery(
    queryKey,
    async ({ pageParam }: { pageParam?: number }) =>
      mmApiClient.private.sets.list.query({
        ...input,
        offset: pageParam ?? 0,
      }),
    {
      ...(options ?? {}),
      getNextPageParam: (_lastPage, pages): number | null => {
        const firstPage = pages[0];
        if (!firstPage || !firstPage.totalCount) {
          return null;
        }
        const totalFetched = pages.reduce((acc, page) => acc + page.items.length, 0);
        if (firstPage.totalCount <= totalFetched) {
          return null;
        }
        return pages.length * (input.limit ?? firstPage.items.length);
      },
    }
  );
  const pages = result.data?.pages;
  const items = useMemo<CollectiblesSet[]>(() => uniqBy(pages?.map(({ items }) => items).flat() ?? [], 'id'), [pages]);
  const totalCount = result.data?.pages?.[0]?.totalCount ?? null;

  const { hasNextPage, fetchNextPage } = result;
  const fetchNextPageIfAvailable = useCallback(() => {
    if (hasNextPage) {
      fetchNextPage();
    }
  }, [hasNextPage, fetchNextPage]);

  return {
    ...result,
    items,
    totalCount,
    fetchNextPageIfAvailable,
    fetchNextPage,
    hasNextPage,
  };
}

export function getSetDisplayText({ name, year, sport }: CollectiblesSet): string {
  return makeSetDisplayName({
    setName: name,
    setYear: year ?? null,
    sportName: sport.name,
  });
}

interface UseSetsByIdsInput {
  setIds: string[];
  collectibleType: CollectibleType;
}

export function useSetsByIds(
  { collectibleType, setIds }: UseSetsByIdsInput,
  options?: MmApiQueryOptions<UseSetsListOutput, UseSetsListQueryKey>
) {
  const queryResults = useAuthenticatedMMAPIQueries(
    setIds.map((setId) => {
      const input = makeSetsListInputById({
        collectibleType,
        setId,
      });
      return {
        queryFn: () => mmApiClient.private.sets.list.query(input),
        queryKey: makeUseSetsListQueryKey(input),
        options,
      };
    })
  );
  const result = queryResults.reduce<{ items: CollectiblesSet[]; isLoading: boolean }>(
    (acc, { data, isLoading }) => {
      const option = data?.items?.[0];
      if (option) {
        acc.items.push(option);
      }
      acc.isLoading = acc.isLoading || isLoading;
      return acc;
    },
    { items: [], isLoading: false }
  );

  const queryClient = useQueryClient();

  const setSetItem = useStableFunctionIdentity((setItem: CollectiblesSet): void => {
    const queryKey = makeUseSetsListQueryKey(
      makeSetsListInputById({
        collectibleType,
        setId: String(setItem.id),
      })
    );
    queryClient.setQueryData<UseSetsListOutput>(queryKey, {
      items: [setItem],
    });
  });

  return {
    ...result,
    setSetItem,
  };
}

interface UseSetByIdInput {
  setId: string;
  collectibleType: CollectibleType;
}

function makeSetsListInputById({ collectibleType, setId }: UseSetByIdInput): UseSetsListInput {
  return {
    filters: {
      setIds: [setId],
    },
    collectibleType,
    limit: 1,
  };
}
